<template>
  <section class="visualizer-wrapper">
    <Visualizer v-if="getZipDirectory"/>
    <slot>
      <button @click.prevent="onConvert"
              :disabled="!getZipDirectory"
              class="raised-btn gradient-action-btn">
        {{ downloading ? 'Downloading...' : 'Download your 3D files 🚀'}}
      </button>
      <button class="raised-btn faded-btn" @click.prevent="onRetry">
        Another One! 👌
      </button>
      <button class="raised-btn gradient-action-btn no-pulse"
              disabled
              @click.prevent="$router.push({ name: 'Fac3dExpressions' })">
        Add Expression 👻 (coming soon!)
      </button>
    </slot>
    <span @click.prevent="scrollBottom" class="material-icons scroll-icon">arrow_circle_down</span>
    <ShareComponent/>
  </section>
</template>
<script>
import Visualizer from '@/components/Visualizer.vue';
import ShareComponent from '@/components/ShareComponent.vue';

export default {
  name: 'Fac3dVisualizer',
  components: {
    Visualizer,
    ShareComponent,
  },
  computed: {
    getZipDirectory() {
      return this.$store.getters.getZipDirectory;
    },
    isUserSubscribed() {
      return this.$store.getters.isUserSubscribed;
    },
  },
  data() {
    return {
      downloading: false,
    };
  },
  methods: {
    onRetry() {
      this.$store.commit('SET_ZIP_DOWNLOAD', null);
      this.$store.dispatch('clearData');
      this.$router.push({ name: 'Fac3dMain' });
    },
    scrollBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    onConvert() {
      if (this.getZipDirectory.length !== 0) {
        this.downloading = true;
        this.$store.commit('DECREMENT_QUOTA');
        return this.$store.dispatch('downloadZipFiles').then(() => {
          this.downloading = false;
          // this.$store.commit('SET_ZIP_DOWNLOAD', null);
          const popupContent = this.isUserSubscribed ? '' : 'Looks like you are not subscribed to our services... Get ahead of the curve and Go Pro for only $5 per month!';
          this.$store.dispatch('setAlert', {
            show: true,
            title: 'Thank you for the download!',
            content: popupContent,
          });
        }, () => {
          this.downloading = false;
        });
      }
      return this.$store.dispatch('setAlert', {
        show: true,
        title: "You don't have any uploaded files!",
        content: 'Please go back and upload a face to start the process.',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.visualizer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 1rem;
  gap: 1rem;
  position: relative;
  z-index: 11;
}
.scroll-icon {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  font-size: 3rem;
  color: white;
  opacity: 0.75;
}
</style>
