<template>
  <section class="ply-section">
    <p class="text-attention blurred-content" v-if="displayWarning || loading">
      Preparing your model... 🤓
    </p>
    <model-ply :src="vis"
      :background-alpha="0" ref="model"
      :lights="lights" :gl-options="glOptions"
      @on-load="init"
      :context="renderContext"
      :rotation="rotation" :height="480" :width="canvasWidth">
    </model-ply>
  </section>
</template>
<script>
import { ModelPly } from 'vue-3d-model';

export default {
  name: 'PlyVisualizer',
  components: {
    ModelPly,
  },
  props: {
    resultType: {
      type: String,
      default: 'default', // 'default' || 'expression'
    },
  },
  computed: {
    canvasWidth() {
      return window.innerWidth * 0.9;
    },
  },
  data() {
    return {
      vis: null,
      loading: false,
      renderContext: null,
      rotation: {
        x: 0,
        // y: Math.PI,
        y: 0,
        z: 0,
      },
      glOptions: {
        capabilities: {
          maxAttributes: 1,
          floatVertexTextures: true,
        },
      },
      // lightsStrength: 1,
      lights: [
        {
          type: 'HemisphereLight',
          position: {
            x: 1, y: -1, z: 0,
          },
          skyColor: 0xaaaaff,
          groundColor: 0x806060,
          intensity: 0.2,
        },
        {
          type: 'DirectionalLight',
          position: {
            x: -0.5, y: -0.75, z: -0.5,
          },
          color: 0xffddcc,
          intensity: 1,
        },
        {
          type: 'DirectionalLight',
          position: {
            x: 0, y: 0, z: 1,
          },
          color: 0xffddcc,
          intensity: 1,
        },
      ],
      displayWarning: false,
    };
  },
  methods: {
    getFile() {
      this.loading = true;
      this.$store.dispatch('getPlyFile')
        .then((res) => {
          // const data = res.data.split('end_header')[1];
          this.vis = res;
          this.$nextTick(() => {
            this.init();
          });
        }, (err) => {
          this.$store.dispatch('setAlert', {
            show: true,
            title: 'Oops 😟 Something wrong happened while getting your results!',
            content: 'Feel free to try again.',
          });
          throw new Error(err);
        });
    },
    init() {
      this.loading = false;
      this.$refs.model.object.material.vertexColors = true;
    },
  },
  mounted() {
    if (this.resultType === 'default') this.getFile();
    this.$nextTick(() => {
      this.displayWarning = true;
      setTimeout(() => {
        this.displayWarning = false;
      }, 3000);
    });
  },
};
</script>
<style scoped lang="scss">
p.text-attention {
  border-radius: 16px;
  color: white;
}
.ply-section {
  margin-bottom: 4rem;
  width: 100%;
}
</style>
