<template>
  <md-list class="share-list">
    <h4 @click.prevent="liked = !liked">
      <md-icon>share</md-icon>
      Like what you see?
    </h4>
    <slot v-if="liked">
      <md-list-item style="background-color:#3b5998;">
        <ShareNetwork
          network="facebook"
          url="https://fac3d.xyz"
          :title="title"
          :description="description"
          :quote="quote"
          hashtags="fac3d,3dmodels,face,deepfakes"
        >
          <md-icon>facebook</md-icon>
          Share on Facebook
        </ShareNetwork>
      </md-list-item>
      <md-list-item style="background-color:#1DA1F2;">
        <ShareNetwork
          network="twitter"
          url="https://fac3d.xyz"
          :title="title"
          :description="description"
        >
          <md-icon>send</md-icon>
          Tweet about it
        </ShareNetwork>
      </md-list-item>
      <md-list-item style="background-color:#128C7E;">
        <ShareNetwork
          network="whatsapp"
          url="https://fac3d.xyz"
          :title="title"
          :description="description"
        >
          <md-icon>textsms</md-icon>
          Send on WhatsApp
        </ShareNetwork>
      </md-list-item>
    </slot>
  </md-list>
</template>
<script>
export default {
  name: 'ShareComponent',
  data() {
    return {
      description: "I'd like to introduce you to Fac3D, It’s a brand new development setup created by stochastx.",
      quote: 'The hot reload is so fast it\'s near instant. - Evan You',
      title: 'Say hi to Fac3D: the easiest way to convert any face to a 3D model!',
      liked: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.share-list {
  margin: 2rem 0;
  &.md-list {
    background: transparent !important;
  }
}
h4, .md-icon {
  color: white !important;
}
.md-list-item-content {
  a, i {
    color: white !important;
  }
}
</style>
